import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { RefObject } from "react";
import { SelectChangeEvent } from "@mui/material";
import { Dayjs } from "dayjs";
type NumberString = {
  [key: string]: string;
};
interface BookingData {
  attributes: {
    sender_id: number,
    status: string,
    request_text: string,
    start_date: string,
    start_time: string,
    end_time: string,
    service_name: string,
    created_at: string,
    sender_full_name: string,
    address: string,
    photo: string,
    frequency: string
  }
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  showEditButton: boolean;
  showSaveButton: boolean;

  openGradeSelect: boolean;
  openStateSelect: boolean;

  currentTabIndex: string;
  profileImageUrl: string;

  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userNumber: string;
  userGrade: string;
  userBirthDate: string;
  userZipCode: string;
  userStreetAddress: string;

  userSchool: string;
  userState: string;
  userCity: string;

  profileImageFile: File | null;
  userType: string;
  userFullName: string;
  activeStartDate: any

  isPhoneNumberValid: boolean;
  isZipCodevalid: boolean;

  stateListData: string[];
  gradeListData: [];
  workInterestGroupCheckBox: NumberString;
  showWnpEditButton: boolean;
  showWnpSaveButton: boolean;

  openAllBookingsOrChooseAvailability: string;

  openCancelPopup: boolean;
  openReceiptPopup: boolean;
  openReschedulePopup: boolean;
  openCalender: boolean;
  openDeclinePopup: boolean;
  declineText: string;
  openAcceptPopup: boolean;
  bookingStateDropdown: string;
  serviceDropdown: string;
  teenDropdown: string;
  newDateData: Date | null;
  upcomingData: BookingData[];
  requestSentOrReceviedData: BookingData[];
  pastData: BookingData[];
  canceledData: BookingData[];
  bookingStateFilterData: [];
  serviceFilterData: [];
  teenFilterData: [];
  addCard: boolean,
  checkedA: boolean
  calenderStatus: boolean
  checkedB: boolean,
  activeTab: boolean
  histroyModal: boolean

  openEmojiPicker: boolean;
  typedMessageData: string;
  isAbove500px: boolean;
  userChatClicked: boolean;

  unAvailableDates: Date | null;
  timeFormatText: string

  pageState: number,
  rowsPerPage: number

  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;

  refElmFile: RefObject<HTMLInputElement>;
  hideChatSummary: RefObject<HTMLInputElement>;
  showChatMessages: RefObject<HTMLInputElement>;
  getPutUserDataAPICallId: string = "";
  getUserDetailsAPICallId: string = "";
  getStateDetailsAPICallId: string = "";
  getGradeDetailsAPICallId: string = "";

  getSeniorUpcomingAPICallId: string = "";
  getSeniorRequestSentAPICallId: string = "";
  getSeniorPastAPICallId: string = "";
  getSeniorCanceledAPICallId: string = "";
  getFilterAPICallId: string = "";

  getTeenUpcomingAPICallId: string = "";
  getTeenRequestReceviedAPICallId: string = "";
  getTeenPastAPICallId: string = "";
  getTeenCanceledAPICallId: string = "";

  mediaQueryList: MediaQueryList;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,

      showEditButton: true,
      showSaveButton: false,

      openGradeSelect: false,
      openStateSelect: false,

      currentTabIndex: "4",
      profileImageUrl: "",

      userFirstName: "",
      userLastName: "",
      userEmail: "",
      userNumber: "",
      userGrade: "",
      userBirthDate: "",
      userZipCode: "",
      userStreetAddress: "",

      userSchool: "",
      userState: "",
      userCity: "",

      profileImageFile: null,
      userType: "",
      userFullName: "",
      isPhoneNumberValid: false,
      isZipCodevalid: false,

      stateListData: [],
      gradeListData: [],

      workInterestGroupCheckBox: { 0: "false" },
      showWnpEditButton: true,
      showWnpSaveButton: false,

      activeStartDate: "",

      openAllBookingsOrChooseAvailability: "All Bookings",

      openCancelPopup: false,
      openReceiptPopup: false,
      openReschedulePopup: false,
      openCalender: false,
      openDeclinePopup: false,
      declineText: "",
      openAcceptPopup: false,
      bookingStateDropdown: "",
      serviceDropdown: "",
      teenDropdown: "",

      newDateData: new Date(),
      unAvailableDates: new Date(),

      upcomingData: [],
      requestSentOrReceviedData: [],
      pastData: [],
      canceledData: [],
      bookingStateFilterData: [],
      serviceFilterData: [],
      teenFilterData: [],
      addCard: false,
      checkedA: false,
      checkedB: false,

      openEmojiPicker: false,
      typedMessageData: "",

      isAbove500px: window.matchMedia('(min-width: 500px)').matches,
      userChatClicked: false,

      calenderStatus: false,
      timeFormatText: "",
      activeTab: false,
      histroyModal: false,

      pageState: 1,
      rowsPerPage: 7,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
    this.refElmFile = React.createRef();
    this.hideChatSummary = React.createRef();
    this.showChatMessages = React.createRef();
    this.mediaQueryList = window.matchMedia('(min-width: 500px)');
    this.uploadPicture = this.uploadPicture.bind(this);
    this.onEmojiClick = this.onEmojiClick.bind(this);

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          currentCountryCode:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId && responseJson) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData) {
              if (regexData.password_validation_regexp) {
                this.passwordReg = new RegExp(
                  regexData.password_validation_regexp
                );
              }

              if (regexData.email_validation_regexp) {
                this.emailReg = new RegExp(regexData.email_validation_regexp);
              }

              if (regexData.email_validation_regexp) {
                this.setState({
                  passwordHelperText: regexData.password_validation_rules
                });
              }
            }
          }
        } else if (apiRequestCallId === this.userProfileGetApiCallId) {
          if (
            responseJson &&
            !responseJson.errors &&
            responseJson.data &&
            responseJson.data.attributes
          ) {
            //On User Profile Success

            this.userAttr = responseJson.data.attributes;

            if (this.userAttr !== null && this.userAttr !== undefined) {
              let email = this.userAttr.email;
              let firstName = this.userAttr.first_name;
              let lastName = this.userAttr.last_name;
              let currentCountryCode = this.userAttr.country_code;
              let phoneNumber = this.userAttr.phone_number
                ? this.userAttr.phone_number
                : "";

              this.setState({
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
              });

              //@ts-ignore
              this.txtInputFirstNameProps.value = firstName;

              //@ts-ignore
              this.txtInputLastNameProps.value = lastName;

              //@ts-ignore
              this.txtInputEmailProps.value = email;

              //@ts-ignore
              this.txtInputPhoneNumberProps.value = phoneNumber;

              this.registrationAndLoginType = this.userAttr.type;

              if (this.userAttr.country_code) {
                this.setState({ currentCountryCode: currentCountryCode });
              }

              if (
                configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: true
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
              ) {
                this.setState({
                  edtEmailEnabled: false,
                  llChangePwdDummyShowContainerVisible: false,
                  llDoChangePwdContainerVisible: false
                });
                this.txtInputEmailProps.editable = false;
              } else if (
                configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
              ) {
                this.setState({
                  llChangePwdDummyShowContainerVisible: true,
                  edtMobileNoEnabled: false,
                  countryCodeEnabled: false
                });
                this.txtInputPhoneNumberProps.editable = false;
              }
            }
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.showAlert("Session Expired", "Please Log in again.");
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiChangePhoneValidation) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success
            this.validateAndUpdateProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        } else if (
          apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
        ) {
          if (responseJson != null && responseJson.errors === undefined) {
            //On Change Phone Validation Success

            this.showAlert("Success", "Profile updated successfully.");
            this.enableDisableEditPassword(false);
            this.getUserProfile();
          } else {
            this.parseApiErrorResponse(responseJson);
          }
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;

        this.getUserProfile();
      }
    }

    this.getUsersData(message);
    this.getStateListData(message);
    this.getGradeListData(message);

    this.getupcomingData(message);
    this.getrequestSentOrReceviedData(message);
    this.getpastData(message);
    this.getcanceledData(message);
    this.getFilterData(message);

    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start

  async componentDidMount() {

    this.mediaQueryList.addEventListener('change', this.handleMediaQueryChange);
    this.mediaQueryList = window.matchMedia('(min-width: 500px)')

    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData);
    let role = loginObject.role;
    let fullName = loginObject.fullName;


    this.setState({
      userType: role,
      userFullName: fullName
    });

    this.getUserDetails();

    if (role !== "Hire A Teen") {
      this.getStateList();
      this.getGradeList();
    }

    if (role === "Hire A Teen") {
      this.callSeniorUpcomingAPI();
      this.callSeniorRequestSentAPI();
      this.callSeniorPastAPI();
      this.callSeniorCanceledAPI();
      this.callFilterAPI();
    } else {
      this.callTeenUpcomingAPI();
      this.callTeenRequestReceviedAPI();
      this.callTeenPastAPI();
      this.callTeenCanceledAPI();
    }

  }

  async componentWillUnmount() {
    this.mediaQueryList.removeEventListener('change', this.handleMediaQueryChange);
  }

  handleMediaQueryChange = (event: any) => {
    this.setState({ isAbove500px: event.matches });
  };

  getUsersData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getUserDetailsAPICallId && !responseJson?.errors) {
        const response = responseJson?.data;
        this.setState({
          userFirstName: response.attributes.first_name,
          userLastName: response.attributes.last_name,
          userEmail: response.attributes.email,
          userNumber: response.attributes.phone_number,
          userSchool: response.attributes.school,
          userGrade: response.attributes.grade,
          userBirthDate: response.attributes.gpa,
          userStreetAddress: response.attributes.address,
          userCity: response.attributes.city,
          userState: response.attributes.state,
          userZipCode: response.attributes.postal_code,
          profileImageUrl: response.attributes.photo,
          isPhoneNumberValid: response.attributes.phone_number ? true : false,
          isZipCodevalid: response.attributes.postal_code ? true : false
        });
      }
    }
  }

  getStateListData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getStateDetailsAPICallId && responseJson && !responseJson.error) {
        const response = responseJson[0];
        const listState = Object.values(response).map(value => value as string);

        this.setState({
          stateListData: listState
        });
      }
    }
  }

  getGradeListData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getGradeDetailsAPICallId && responseJson && !responseJson.error) {
        this.setState({
          gradeListData: responseJson
        });
      }
    }
  }

  getupcomingData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getSeniorUpcomingAPICallId && responseJson && !responseJson.error) {
        this.setState({
          upcomingData: responseJson.data
        });
      }
      if (apiRequestCallId === this.getTeenUpcomingAPICallId && responseJson && !responseJson.error) {
        this.setState({
          upcomingData: responseJson.data
        });
      }
    }
  }

  getrequestSentOrReceviedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getSeniorRequestSentAPICallId && responseJson && !responseJson.error) {
        this.setState({
          requestSentOrReceviedData: responseJson.data
        });
      }
      if (apiRequestCallId === this.getTeenRequestReceviedAPICallId && responseJson && !responseJson.error) {
        this.setState({
          requestSentOrReceviedData: responseJson.data
        });
      }
    }
  }

  getpastData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getSeniorPastAPICallId && responseJson && !responseJson.error) {
        this.setState({
          pastData: responseJson.data
        });
      }
      if (apiRequestCallId === this.getTeenPastAPICallId && responseJson && !responseJson.error) {
        this.setState({
          pastData: responseJson.data
        });
      }
    }
  }

  getcanceledData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getSeniorCanceledAPICallId && responseJson && !responseJson.error) {
        this.setState({
          canceledData: responseJson.data
        });
      }
      if (apiRequestCallId === this.getTeenCanceledAPICallId && responseJson && !responseJson.error) {
        this.setState({
          canceledData: responseJson.data
        });
      }
    }
  }

  getFilterData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getFilterAPICallId && responseJson && !responseJson.error) {
        this.setState({
          bookingStateFilterData: responseJson.bookings,
          serviceFilterData: responseJson.categories,
          teenFilterData: responseJson.requests
        });
      }
    }
  }

  getUserDetails() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserDetailsAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserAPIEndpoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  hideTabs() {
    if (this.state.userType === "Hire A Teen") {
      return false;
    } else {
      return true;
    }
  }

  hideBoxes() {
    if (this.state.userType !== "Hire A Teen") {
      return "flex";
    } else {
      return "none";
    }
  }

  hideBoxesReverse() {
    if (this.state.userType === "Hire A Teen") {
      return "flex";
    } else {
      return "none";
    }
  }

  addressParameter() {
    if (this.state.userType !== "Hire A Teen") {
      return "Street Address";
    } else {
      return "Address";
    }
  }

  addressParameterWidth() {
    if (this.state.userType !== "Hire A Teen") {
      return "335px";
    } else {
      return "408px";
    }
  }

  pendingOrReceivedRequests() {
    return this.state.userType === "Hire A Teen" ? "Pending" : "Received Requests"
  }

  isNumber(value: number) {
    return !isNaN(parseFloat(String(value))) && isFinite(value);
  }

  cellNumberHelperText() {
    if (!this.state.userNumber || this.state.isPhoneNumberValid) {
      return "";
    } else {
      return "Invalid Cell Number";
    }
  }

  zipCodeHelperText() {
    if (!this.state.userZipCode || this.state.isZipCodevalid) {
      return "";
    } else {
      return "Invalid Zip Code";
    }
  }

  handleErrorBorder1() {
    if (!this.state.userNumber || this.state.isPhoneNumberValid) {
      return "1px solid #D6DDEB";
    } else {
      return "1px solid #FF6550";
    }
  }

  handleErrorBorder2() {
    if (!this.state.userZipCode || this.state.isZipCodevalid) {
      return "1px solid #D6DDEB";
    } else {
      return "1px solid #FF6550";
    }
  }

  handleAllBookingAndChooseAvailability(type: string) {
    this.setState({
      openAllBookingsOrChooseAvailability: type, calenderStatus: false
    });
  }

  handleCancelPopup() {
    this.setState({
      openCancelPopup: true
    })
  }

  handleDeclinePopup() {
    this.setState({
      openDeclinePopup: true
    })
  }

  handleAcceptPopup() {
    this.setState({
      openAcceptPopup: true
    })
  }

  handleReceiptPopup() {
    this.setState({
      openReceiptPopup: true
    })
  }

  handleReschedulePopup() {
    this.setState({
      openReschedulePopup: true,
      openCalender: true
    })
  }

  handleMessageNavigation() {
    this.setState({
      currentTabIndex: "2"
    })
  }

  closeCancelPopup() {
    this.setState({ openCancelPopup: false });
  }

  closeReceiptPopup() {
    this.setState({ openReceiptPopup: false });
  }

  closeReschedulePopup() {
    this.setState({ openReschedulePopup: false, openCalender: false })
  }

  closeAcceptPopup() {
    this.setState({ openAcceptPopup: false })
  }

  closeDeclinePopup() {
    this.setState({ openDeclinePopup: false })
  }

  changeDeclineText(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      declineText: event.target.value
    })
  }

  handleBookingStateDropdown(event: SelectChangeEvent) {
    this.setState({
      bookingStateDropdown: event.target.value
    })
  }

  handleServiceDropdown(event: SelectChangeEvent) {
    this.setState({
      serviceDropdown: event.target.value
    })
  }

  handleTeenDropdown(event: SelectChangeEvent) {
    this.setState({
      teenDropdown: event.target.value
    })
  }

  handleDatePicker(event: Dayjs | null) {
    let date: Date | null = null;
    if (event) {
      date = event.toDate();
    }
    this.setState({
      newDateData: date
    });
  }

  getStateList() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStateDetailsAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getGradeList() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getGradeDetailsAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGradeAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  handleEdit() {
    this.setState({
      showEditButton: false,
      showSaveButton: true
    })
  }

  handleEditWnpButton() {
    this.setState({
      showWnpEditButton: false,
      showWnpSaveButton: true
    })
  }

  handleSaveWnpButton() {
    this.setState({
      showWnpEditButton: true,
      showWnpSaveButton: false
    });
  }

  async handleSave() {
    try {
      await this.postUserData();

      let loginData = localStorage.getItem("login") as string;
      let loginObject = JSON.parse(loginData);

      let updatedLoginObj = {
        "id": loginObject.id,
        "email": loginObject.email,
        "role": loginObject.role,
        "activated": loginObject.activated,
        "token": loginObject.token,
        "fullName": this.state.userFirstName + " " + this.state.userLastName
      }
      localStorage.setItem("login", JSON.stringify(updatedLoginObj));
      this.setState({
        userFullName: this.state.userFirstName + " " + this.state.userLastName
      });
    } catch (error) {

    }

    this.setState({
      showEditButton: true,
      showSaveButton: false
    });
  }

  openStateDropdown() {
    this.setState({
      openStateSelect: !this.state.openStateSelect
    })
  }

  openGradeDropdown() {
    this.setState({
      openGradeSelect: !this.state.openGradeSelect
    })
  }

  handleTabChange(event: React.SyntheticEvent, newValue: string) {
    this.setState({
      currentTabIndex: newValue,
      userChatClicked: false
    });
  }

  uploadPicture() {
    this.refElmFile.current?.click();
  }

  async selectPicture(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const base64String = await this.resizeImage(file);
      this.setState({
        profileImageUrl: `data:image/jpeg;base64,${base64String}`,
        profileImageFile: file
      });

    }
  }

  userProfileBorder() {
    if (this.state.profileImageUrl) {
      return "4px solid #5E70DD"
    } else {
      return "4px solid #DDE1E6"
    }
  }

  changeSchool(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userSchool: event.target.value
    });
  }

  changeUserState(event: SelectChangeEvent) {
    this.setState({
      userState: event.target.value
    });
  }

  changeCity(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userCity: event.target.value
    });
  }

  changeFirstName(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userFirstName: event.target.value
    });
  }

  changeLastName(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userLastName: event.target.value
    });
  }

  changeEmail(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userEmail: event.target.value
    });
  }

  changeNumber(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    console.log(isValid);
    this.setState({
      isPhoneNumberValid: isValid,
      userNumber: event.target.value
    });
  }

  changeGrade(event: SelectChangeEvent) {
    this.setState({
      userGrade: event.target.value
    })
  }

  changeBirthDate(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userBirthDate: event.target.value
    })
  }

  changeStreet(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      userStreetAddress: event.target.value
    })
  }

  changeZipCode(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const isValid = this.isNumber(Number(event.target.value));
    this.setState({
      isZipCodevalid: isValid,
      userZipCode: event.target.value
    })
  }

  handleWnpCheckbox(event: React.ChangeEvent<HTMLInputElement>, keyData: number) {
    let dataObject = { ...this.state.workInterestGroupCheckBox };

    dataObject[keyData.toString()] = `${event.target.checked}`

    this.setState({
      workInterestGroupCheckBox: { ...dataObject }
    })
  }

  resizeImage(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.onLoad(event, resolve, reject);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  onLoad(event: any, resolve: any, reject: any) {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      this.onLoadImage(img, resolve);
    };
    img.onerror = (error) => reject(error);
  }

  onLoadImage(img: any, resolve: any) {
    const canvas = document.createElement('canvas');
    const maxDimension = 300;
    const scaleSize = maxDimension / Math.max(img.width, img.height);
    canvas.width = img.width * scaleSize;
    canvas.height = img.height * scaleSize;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
    const resizedBase64 = canvas.toDataURL('image/jpeg', 0.7)?.split(',')[1];
    resolve(resizedBase64);
  }

  async postUserData() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    let body = {
      first_name: this.state.userFirstName,
      last_name: this.state.userLastName,
      phone_number: this.state.userNumber,
      school: this.state.userSchool,
      grade: this.state.userGrade,
      gpa: this.state.userBirthDate,
      address: this.state.userStreetAddress,
      city: this.state.userCity,
      state: this.state.userState,
      postal_code: this.state.userZipCode,
      ...(this.state.profileImageFile && { photo: this.state.profileImageUrl })
    }

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPutUserDataAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.putAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body));

    runEngine.sendMessage(getAllData.id, getAllData);
  }

  handleCheck1() {
    return this.state.openStateSelect && !this.state.showEditButton;
  }

  handleCheck2() {
    return this.state.openGradeSelect && !this.state.showEditButton;
  }

  handleChecks(keyData: number) {
    if (this.state.workInterestGroupCheckBox[keyData] === "true") {
      return true
    }
    else {
      return false
    }
  }

  handleColorAllBookings() {
    if (this.state.openAllBookingsOrChooseAvailability == "All Bookings") {
      return "#25324B"
    } else {
      return "#7C8493"
    }
  }

  handleColorChooseAvailability() {
    if (this.state.openAllBookingsOrChooseAvailability != "All Bookings") {
      return "#25324B"
    } else {
      return "#7C8493"
    }
  }

  callSeniorUpcomingAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSeniorUpcomingAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seniorUpcomingAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callSeniorRequestSentAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSeniorRequestSentAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seniorSentRequestAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callSeniorPastAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSeniorPastAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seniorPastAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callSeniorCanceledAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSeniorCanceledAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seniorCanceledAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callFilterAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getFilterAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookingFilterAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callTeenUpcomingAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeenUpcomingAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teenUpcomingAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callTeenRequestReceviedAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeenRequestReceviedAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teenReceviedRequestAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callTeenPastAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeenPastAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teenPastAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  callTeenCanceledAPI() {
    let loginData = localStorage.getItem("login") as string;
    let loginObject = JSON.parse(loginData)

    const PCheaders = {
      "Content-Type": configJSON.jsonContentType,
      token: loginObject?.token
    };

    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeenCanceledAPICallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teenCanceledAPIEndPoint}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  loadAllChats() {
    this.setState({
      userChatClicked: false
    });
    if (!this.state.isAbove500px && this.showChatMessages.current && this.hideChatSummary.current) {
      this.hideChatSummary.current.style.display = "flex";
      this.showChatMessages.current.style.display = "none"
    }
  }

  handleClickMessageSummary() {
    this.setState({
      userChatClicked: true
    })
    if (!this.state.isAbove500px && this.showChatMessages.current && this.hideChatSummary.current) {
      this.hideChatSummary.current.style.display = "none";
      this.showChatMessages.current.style.display = "flex"
    }
  }

  handleTypedMessage(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    this.setState({
      typedMessageData: event.target.value
    })
  }

  openEmojiPicker() {
    this.setState({
      openEmojiPicker: !this.state.openEmojiPicker
    });
  }

  onEmojiClick(event: any) {
    this.setState({
      typedMessageData: this.state.typedMessageData + event.emoji,
      openEmojiPicker: false
    });
  }

  handleShowHideChats() {
    return this.state.userChatClicked ? "flex !important" : "none !important"
  }

  handleChatChecks1(alignRight: boolean) {
    return alignRight ? "end" : "flex-start";
  }

  handleChatChecks2(alignRight: boolean) {
    return alignRight ? "row-reverse" : "row";
  }

  handleChatChecks3(alignRight: boolean) {
    return alignRight ? "#F0E5FF" : "#FFFFFF";
  }

  handleChatChecks4(alignRight: boolean) {
    return alignRight ? "14px 0px 14px 14px" : "0px 14px 14px 14px";
  }

  handleChatChecks5(alignRight: boolean) {
    return alignRight ? "flex-end" : "flex-start";
  }

  handleChatChecks6(alignRight: boolean) {
    return alignRight ? "1px solid #F0E5FF" : "1px solid #E7E7E7";
  }

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ pageState: newPage });
  };

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }


  handleCalender = () => {
    this.setState({ calenderStatus: !this.state.calenderStatus })
  }


  handleChange = (event: any) => {
    let { checked, name } = event.target
    if (name === "save") {
      this.setState({ checkedA: checked })
    } else {
      this.setState({ checkedB: checked })
    }
  }
  handleAddCard = () => {
    this.setState({ addCard: true })
  }
  manageTabs = (status: boolean) => {
    this.setState({ activeTab: status })
  }

  handleUnavailableDatePicker(event: Dayjs | null) {
    let date: Date | null = null;
    if (event) {
      date = event.toDate();
    }
    this.setState({
      unAvailableDates: date
    });
  }

  handleTextChange = (event: any) => {
    this.setState({ timeFormatText: event.target.value })
  }
  handleModal = () => {
    this.setState({ histroyModal: true })
  }

  closeModal = () => {
    this.setState({ histroyModal: false })
  }


  // Customizable Area End

}
